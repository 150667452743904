.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.text {
    margin: 2vw 0 4vw 0;
    text-align: center;
    font-family: GmarketSansMedium;
    font-size: 2vw;
    color: #8e6123;
}