.container {
    width: 100%;
    height: 100%;
    position: relative;
}

.benerImage {
    width: 100vw;
    height: 50vh;
    object-fit: cover;
    opacity: 0;
    transform: scale(1.1);
    transition: opacity 1s ease, transform 1s ease;

    &.showImage {
        opacity: 1;
        transform: scale(1);
    }

    @media (max-width: 900px) {
        margin-top: 15vw;
        height: 40vw;
    }
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50vh;
    background-color: rgba(0, 0, 0, 0.4); // 50% 투명도
    pointer-events: none;

    @media (max-width: 900px) {
        top: 15vw;
        height: 40vw;
    }
}

.contents {
    position: absolute;
    top: 29vh;
    left: 50%;
    transform: translate(-50%, -7vh);
    text-align: center;
    color: #FFFFFF;
    width: 80%;
    opacity: 0;
    transition: opacity 2s ease;

    &.showContents {
        opacity: 1;
    }

    @media (max-width: 900px) {
        top: 70%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .title {
        margin-bottom: 1vw;
        font-family: "Noto Sans KR", sans-serif;
        font-optical-sizing: auto;
        font-weight: 500;
        font-size: 1vw;
        opacity: 0;
        transform: translateY(-20px);
        transition: opacity 2s ease, transform 2s ease, font-size 1s ease-out;

        &.showTitle {
            opacity: 1;
            transform: translateY(0);
            font-size: 3vw; /* 크기 살짝 커지는 효과 */
        }

        @media (max-width: 900px) {
            font-size: 8vw;
        }
    }

    .text {
        margin: 0.3vw;
        font-size: 1vw;
        opacity: 0;
        transform: translateY(20px);
        transition: opacity 2s ease, transform 2s ease;

        &.showText {
            opacity: 1;
            transform: translateY(0);
        }

        span {
            font-weight: 800;
        }

        @media (max-width: 900px) {
            margin: 0.5vw;
            font-size: 2vw;
        }
    }
}
